import http from '@/utils/http';

/**
 * 会员分组
 */
export default {
  getList: {
    p: 'post,/manage/membergroup/getlist',
    r: (data) => http({ url: '/manage/membergroup/getlist', method: 'post', data })
  },
  add: {
    p: 'post,/manage/membergroup/add',
    r: (data) => http({ url: '/manage/membergroup/add', method: 'post', data })
  },
  update: {
    p: 'post,/manage/membergroup/update',
    r: (data) => http({ url: '/manage/membergroup/update', method: 'post', data })
  },
  del: {
    p: 'post,/manage/membergroup/delete',
    r: (data) => http({ url: '/manage/membergroup/delete', method: 'post', data })
  },
  getOne: {
    p: 'post,/manage/membergroup/getOne',
    r: (data) => http({ url: '/manage/membergroup/getOne', method: 'post', data })
  }
};
