import http from '@/utils/http';

/**
 * 会员等级
 */
export default {
  getList: {
    p: 'post,/manage/memberrank/getlist',
    r: (data) => http({ url: '/manage/memberrank/getlist', method: 'post', data })
  },
  add: {
    p: 'post,/manage/memberrank/add',
    r: (data) => http({ url: '/manage/memberrank/add', method: 'post', data })
  },
  update: {
    p: 'post,/manage/memberrank/update',
    r: (data) => http({ url: '/manage/memberrank/update', method: 'post', data })
  },
  del: {
    p: 'post,/manage/memberrank/delete',
    r: (data) => http({ url: '/manage/memberrank/delete', method: 'post', data })
  },
  getOne: {
    p: 'post,/manage/memberrank/getOne',
    r: (data) => http({ url: '/manage/memberrank/getOne', method: 'post', data })
  }
};
