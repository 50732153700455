<template>
  <div class="page-class setting">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="系统设置" name="system"></el-tab-pane>
      <el-tab-pane label="公众号配置" name="wechat"></el-tab-pane>
      <el-tab-pane label="运营设置" name="other"></el-tab-pane>
      <el-tab-pane label="短信配置" name="sms"></el-tab-pane>
    </el-tabs>
    <div style="width:70%">
    <avue-form ref="form" v-model="formDa" :option="formOp" @submit="handleSubmit">
      <template slot="operator">
        <el-select
          v-model="formDa.operator"
          filterable
          remote
          multiple
          placeholder="请输入关键词"
          suffix-icon="el-icon-search"
          @change="memberChangeSelect"
          @visible-change="memberChangeSelect"
          :remote-method="getMember">
          <el-option
            v-for="item in member"
            :key="item.id"
            :label="item.nickname"
            :value="item.id">
          </el-option>
        </el-select>
      </template>
    </avue-form>
    </div>
  </div>
</template>
<script>
import List from './list';

export default List;
</script>
