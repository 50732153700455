import './list.scss';
import dataApi from '@/api/system/index.js';
import groupApi from '@/api/manage/memberGroup.js';
import rankApi from '@/api/manage/memberRank.js';
import memberApi from '@/api/member/member.js';
import { validatenull } from '@/utils/validate.js';

// const EDITORCONF = [
//   'head',
//   'italic',
//   'underline',
//   'strikeThrough',
//   'lineHeight',
//   'backColor',
//   'link',
//   'todo',
//   'justify',
//   'quote',
//   'image',
//   'video',
//   'table',
//   'code',
//   'splitLine',
//   'undo',
//   'redo'
// ];
export default {
  name: 'setting',
  components: {},
  data() {
    return {
      params: {
        zoom: 10
        // zoomEnable: false,
        // dragEnable: false,
      },
      activeName: 'system',
      member: [],
      memberGroup: [],
      memberRank: [],
      formDa: {
        order_success: [
          {
            template_id: '',
            first: '',
            remark: '',
            keyword: []
          }
        ],
        arranging: [
          {
            template_id: '',
            first: '',
            remark: '',
            keyword: []
          }
        ],
        energy_up: [
          {
            template_id: '',
            first: '',
            remark: '',
            keyword: []
          }
        ],
        points_up: [
          {
            template_id: '',
            first: '',
            remark: '',
            keyword: []
          }
        ],
        register: [
          {
            template_id: '',
            first: '',
            remark: '',
            keyword: ['']
          }
        ]
      },
      formOp: {
        column: [],
        group: [],
        labelPosition: 'right',
        labelSuffix: '：',
        labelWidth: 170,
        gutter: 0,
        menuBtn: true,
        submitBtn: true,
        submitText: '提交',
        emptyBtn: false,
        emptyText: '清空',
        menuPosition: 'center',
        detail: false
      },
      formSystem: [
        // {
        //   type: 'input',
        //   label: '服务器地址',
        //   span: 24,
        //   display: true,
        //   prop: 'weburl',
        //   placeholder: '请输入服务器地址'
        // },
        // {
        //   type: 'input',
        //   label: '网站名称',
        //   span: 24,
        //   display: true,
        //   prop: 'webtitle'
        // },
        // {
        //   type: 'input',
        //   label: '网站关键词',
        //   span: 24,
        //   display: true,
        //   prop: 'keywords',
        //   tip: '多个关键词用“,”分隔开',
        //   tipPlacement: 'bottom'
        // },
        {
          type: 'input',
          label: '门店位置',
          span: 24,
          display: true,
          prop: 'address'
        },
        {
          type: 'map',
          label: '地图位置',
          span: 24,
          display: true,
          prop: 'address_map'
        },
        {
          type: 'input',
          label: '位置卡片背景色',
          span: 12,
          display: true,
          prop: 'map_bg_color'
        },
        {
          type: 'input',
          label: '位置卡片文本色',
          span: 12,
          display: true,
          prop: 'map_text_color'
        },
        {
          type: 'input',
          label: '客服电话',
          span: 12,
          display: true,
          prop: 'telephone'
        },
        {
          type: 'input',
          label: '客服微信号',
          span: 12,
          display: true,
          prop: 'wechat_id'
        },
        {
          label: '客服二维码',
          span: 12,
          display: true,
          type: 'upload',
          prop: 'service_qr',
          propsHttp: {},
          dataType: 'string',
          listType: 'picture-img',
          multiple: false,
          limit: 1,
          accept: 'image/png, image/jpeg',
          tip: '只能上传jpg/png文件，且不超过500kb',
          action: `${this.$baseUrl}system/upload`
        },
        {
          label: '小程序分享封面',
          span: 12,
          display: true,
          type: 'upload',
          prop: 'share_cover',
          propsHttp: {},
          dataType: 'string',
          listType: 'picture-img',
          multiple: false,
          limit: 1,
          accept: 'image/png, image/jpeg',
          tip: '只能上传jpg/png文件，且不超过500kb',
          action: `${this.$baseUrl}system/upload`
        },
        {
          label: '家庭成员邀请封面',
          span: 12,
          display: true,
          type: 'upload',
          prop: 'family_cover',
          propsHttp: {},
          dataType: 'string',
          listType: 'picture-img',
          multiple: false,
          limit: 1,
          accept: 'image/png, image/jpeg',
          tip: '只能上传jpg/png文件，且不超过500kb',
          action: `${this.$baseUrl}system/upload`
        },
        {
          label: '系统默认图片',
          span: 12,
          display: true,
          type: 'upload',
          prop: 'default_image',
          propsHttp: {},
          dataType: 'string',
          listType: 'picture-img',
          multiple: false,
          limit: 1,
          accept: 'image/png, image/jpeg',
          tip: '只能上传jpg/png文件，且不超过500kb',
          action: `${this.$baseUrl}system/upload`
        },
        {
          label: '系统默认图像',
          span: 12,
          display: true,
          type: 'upload',
          prop: 'default_avatar',
          propsHttp: {},
          dataType: 'string',
          listType: 'picture-img',
          multiple: false,
          limit: 1,
          accept: 'image/png, image/jpeg',
          tip: '只能上传jpg/png文件，且不超过500kb,建议尺寸188*188px',
          action: `${this.$baseUrl}system/upload`
        }
      ],
      formWechat: [
        {
          label: '公众号配置',
          prop: 'account',
          arrow: false,
          collapse: true,
          display: true,
          column: [
            {
              type: 'input',
              label: 'AppID',
              span: 24,
              display: true,
              prop: 'appId',
              placeholder: '开发者ID(AppID)'
            },
            {
              type: 'input',
              label: 'AppSecret',
              span: 24,
              display: true,
              prop: 'appSecret',
              placeholder: '开发者密码(AppSecret)'
            },
            {
              type: 'input',
              label: '令牌(Token)',
              span: 24,
              display: true,
              prop: 'token'
            },
            {
              type: 'input',
              label: 'EncodingAESKey',
              span: 24,
              display: true,
              prop: 'aeskey',
              placeholder: '消息加解密密钥(EncodingAESKey)'
            },
            {
              label: '公众号二维码',
              span: 24,
              display: true,
              type: 'upload',
              prop: 'wechat_qr',
              propsHttp: {},
              dataType: 'string',
              listType: 'picture-img',
              oss: 'ali',
              multiple: false,
              limit: 1,
              accept: 'jpg/png',
              tip: '只能上传jpg/png文件，且不超过500kb',
              action: `${this.$baseUrl}system/upload`
            }
          ]
        },
        {
          label: '模板消息',
          prop: 'tplgroup',
          arrow: false,
          collapse: true,
          display: true,
          column: [
            {
              title: '警告',
              component: 'el-alert',
              span: 24,
              params: {
                title: '公众号模板消息模板id从微信公众号后台获取',
                type: 'warning'
              }
            },
            // {
            //   type: 'input',
            //   label: '下单成功',
            //   span: 24,
            //   display: true,
            //   prop: 'order_success',
            //   placeholder: '订单创建成功'
            // },
            {
              label: '下单成功',
              prop: 'order_success',
              type: 'dynamic',
              span: 24,
              children: {
                align: 'center',
                index: false,
                addBtn: false,
                delBtn: false,
                headerAlign: 'center',
                column: [
                  {
                    width: 200,
                    label: '模板id',
                    prop: 'template_id'
                  },
                  {
                    width: 200,
                    label: '消息说明',
                    prop: 'first'
                  },
                  {
                    width: 200,
                    label: '备注',
                    prop: 'remark'
                  },
                  {
                    label: '数组框',
                    prop: 'keyword',
                    type: 'array',
                    value: ['']
                  }
                ]
              }
            },
            {
              label: '排课成功',
              prop: 'arranging',
              type: 'dynamic',
              span: 24,
              children: {
                align: 'center',
                index: false,
                addBtn: false,
                delBtn: false,
                headerAlign: 'center',
                column: [
                  {
                    width: 200,
                    label: '模板id',
                    prop: 'template_id'
                  },
                  {
                    width: 200,
                    label: '消息说明',
                    prop: 'first'
                  },
                  {
                    width: 200,
                    label: '备注',
                    prop: 'remark'
                  },
                  {
                    label: '数组框',
                    prop: 'keyword',
                    type: 'array',
                    value: ['']
                  }
                ]
              }
            },
            {
              label: '能量值更新',
              prop: 'energy_up',
              type: 'dynamic',
              span: 24,
              children: {
                align: 'center',
                index: false,
                addBtn: false,
                delBtn: false,
                headerAlign: 'center',
                column: [
                  {
                    width: 200,
                    label: '模板id',
                    prop: 'template_id'
                  },
                  {
                    width: 200,
                    label: '消息说明',
                    prop: 'first'
                  },
                  {
                    width: 200,
                    label: '备注',
                    prop: 'remark'
                  },
                  {
                    label: '数组框',
                    prop: 'keyword',
                    type: 'array',
                    value: ['']
                  }
                ]
              }
            },
            {
              label: '积分更新',
              prop: 'points_up',
              type: 'dynamic',
              span: 24,
              children: {
                align: 'center',
                index: false,
                addBtn: false,
                delBtn: false,
                headerAlign: 'center',
                column: [
                  {
                    width: 200,
                    label: '模板id',
                    prop: 'template_id'
                  },
                  {
                    width: 200,
                    label: '消息说明',
                    prop: 'first'
                  },
                  {
                    width: 200,
                    label: '备注',
                    prop: 'remark'
                  },
                  {
                    label: '数组框',
                    prop: 'keyword',
                    type: 'array',
                    value: ['']
                  }
                ]
              }
            },
            {
              label: '注册',
              prop: 'register',
              type: 'dynamic',
              span: 24,
              children: {
                align: 'center',
                index: false,
                addBtn: false,
                delBtn: false,
                headerAlign: 'center',
                column: [
                  {
                    width: 200,
                    label: '模板id',
                    prop: 'template_id'
                  },
                  {
                    width: 200,
                    label: '消息说明',
                    prop: 'first'
                  },
                  {
                    width: 200,
                    label: '备注',
                    prop: 'remark'
                  },
                  {
                    label: '内容项',
                    prop: 'keyword',
                    type: 'array',
                    value: ['']
                  }
                ]
              }
            }
            // {
            //   type: 'input',
            //   label: '排课成功',
            //   span: 24,
            //   display: true,
            //   prop: 'arranging',
            //   placeholder: '排课成功'
            // },
            // {
            //   type: 'input',
            //   label: '能量值更新',
            //   span: 24,
            //   display: true,
            //   prop: 'energy_up',
            //   placeholder: '能量值更新提醒'
            // },
            // {
            //   type: 'input',
            //   label: '积分更新',
            //   span: 24,
            //   display: true,
            //   prop: 'points_up',
            //   placeholder: '积分更新提醒'
            // }
          ]
        },
        {
          label: '视频号配置',
          prop: 'video',
          arrow: false,
          collapse: true,
          display: true,
          column: [
            {
              type: 'input',
              label: '视频号ID',
              span: 24,
              display: true,
              prop: 'finderUserName',
              placeholder:
                '获取视频号ID的需要登录视频号助手，在首页可以查看自己的视频号ID'
            }
          ]
        }
      ],
      formOther: [
        {
          type: 'select',
          label: '默认会员等级',
          span: 24,
          display: true,
          props: {
            label: 'name',
            value: 'id'
          },
          prop: 'default_member_rank',
          dataType: 'number'
        },
        {
          type: 'select',
          label: '默认会员组',
          span: 24,
          display: true,
          props: {
            label: 'name',
            value: 'id'
          },
          prop: 'default_member_group',
          dataType: 'number'
        },
        {
          type: 'number',
          label: '注册赠送积分',
          span: 12,
          display: true,
          prop: 'giving_ponits',
          placeholder: '注册赠送积分'
        },
        {
          type: 'number',
          label: '注册赠送能量值',
          span: 12,
          display: true,
          prop: 'giving_evergy',
          placeholder: '注册赠送能量值'
        },
        {
          type: 'number',
          label: '签到赠送积分',
          span: 12,
          display: true,
          prop: 'sign_points',
          placeholder: '签到赠送积分'
        },
        {
          type: 'number',
          label: '签到赠送能量值',
          span: 12,
          display: true,
          prop: 'sign_evengy',
          disabled: true,
          placeholder: '签到赠送能量值'
        },
        {
          type: 'radio',
          label: '是否开启补签',
          span: 12,
          display: true,
          prop: 'retroactive',
          dicData: [
            {
              label: '开启',
              value: '1'
            },
            {
              label: '关闭',
              value: '0'
            }
          ]
        },
        {
          type: 'number',
          label: '补签消耗积分',
          span: 12,
          display: true,
          prop: 'remove_points',
          placeholder: '签到赠送能量值'
        },
        {
          type: 'number',
          label: '能量值充值比例',
          span: 12,
          display: true,
          prop: 'topup_prop',
          placeholder: '能量值充值比例1:1',
          tip: '填写能量值数量1:X'
        }
      ]
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.formOp.group = [];
    this.formOp.column = this.formSystem;
    this.getInfo('system');
    this.getMemberGroup();
    this.getMemberRank();
    this.getMember();
  },
  filters: {},
  methods: {
    mapClick() {
      console.log(213123);
    },
    // 返回
    goBack() {
      this.$router.go(-1);
    },
    // 选项卡切换
    handleClick(tab) {
      switch (tab.name) {
        case 'system':
          this.formOp.group = [];
          this.formOp.column = this.formSystem;
          break;
        case 'wechat':
          this.formOp.column = [];
          this.formOp.group = this.formWechat;
          break;
        case 'sms':
          this.formOp.group = [];
          this.formOp.column = this.formSms;
          break;
        case 'other':
          this.formOp.group = [];
          this.formOp.column = this.formOther;
          this.$refs.form.updateDic('default_member_group', this.memberGroup);
          this.$refs.form.updateDic('default_member_rank', this.memberRank);
          break;
        default:
          break;
      }
      this.formDa = { ...undefined };
      this.$refs.form.resetFields();
      this.getInfo(tab.name);
    },
    dispose(obj) {
      if (obj.instanceof === 'Array') {
        if (obj[0].keyword.length > 0) {
          obj[0].keyword.forEach((item, index) => {
            obj[0][`keyword${index + 1}`] = item;
          });
        }
        delete obj[0].keyword;
        return JSON.stringify(obj[0]);
      }
    },

    // 添加
    handleSubmit(form, done) {
      console.log(form);
      form.order_success = this.dispose(form.order_success);
      form.arranging = this.dispose(form.arranging);
      form.energy_up = this.dispose(form.energy_up);
      form.points_up = this.dispose(form.points_up);
      form.register = this.dispose(form.register);
      const field = JSON.stringify(form);
      const postData = { scenario: this.activeName, field };
      console.log(postData);
      done();
      dataApi.confPost.r(postData).then((res) => {
        done(form);
        this.formDa = res.data;
        this.getInfo(this.activeName);
      });
    },
    strDispose(obj) {
      obj = JSON.parse(obj);
      const arr = [];
      console.log(obj);
      for (const objKey in obj) {
        if (objKey.match(/keyword/g)) {
          console.log(objKey);
          arr.push(obj[objKey]);
          delete obj[objKey];
        }
      }
      obj.keyword = arr;
      return [obj];
    },
    getInfo(value) {
      const postData = {
        scenario: value
      };
      const that = this;
      that.formDa = {};
      dataApi.confInfo.r(postData).then((res) => {
        console.log(res.data);
        if (!validatenull(res.data)) {
          if (value === 'wechat') {
            that.formDa = res.data;
            that.formDa.order_success = that.strDispose(res.data.order_success);
            this.formDa.arranging = this.strDispose(res.data.arranging);
            this.formDa.energy_up = this.strDispose(res.data.energy_up);
            this.formDa.points_up = this.strDispose(res.data.points_up);
            this.formDa.register = this.strDispose(res.data.register);
          } else {
            that.formDa = res.data;
          }
        }
      });
    },
    memberChangeSelect() {},
    getMember(query) {
      const postData = {
        nickname: query,
        page: 1,
        pageSize: 10
      };
      memberApi.getList.r(postData).then((res) => {
        this.member = res.data.list;
      });
    },
    getMemberRank() {
      const postData = {
        page: 1,
        pageSize: 100
      };
      rankApi.getList.r(postData).then((res) => {
        this.memberRank = res.data.list;
      });
    },
    getMemberGroup() {
      const postData = {
        page: 1,
        pageSize: 100
      };
      groupApi.getList.r(postData).then((res) => {
        this.memberGroup = res.data.list;
      });
    }
  }
};
